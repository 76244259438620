import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ImportScriptMeshkraftViewer, useQuery } from '../utils/helpers';
import Footer from '../components/Footer';

function Product() {
  const { slug } = useParams();
  let query = useQuery();

  const token = query.get('token');
  const arButton = query.get('arButton');
  const wireframe = query.get('wireframe');

  let productRef = useRef(null);

  useEffect(() => {
    const myElement = document.querySelector('meshkraft-viewer');

    if (myElement) {
      myElement.addEventListener('product-fetched', (e) => {
        productRef.current = e;
      });
    }
    return () => {
      if (myElement) {
        myElement.removeEventListener('product-fetched', (e) => {});
      }
    };
  }, []);

  return (
    <>
      {token && (
        <ImportScriptMeshkraftViewer
          resourceUrl={
            'https://unpkg.com/meshkraft-viewer@latest/dist/meshkraft-viewer.min.js'
          }
          meshkraftKey={token}
        />
      )}

      <Footer slug={slug} arButton={arButton} />
      <Main sku={slug} product={productRef.current} />
    </>
  );
}

const Main = ({ sku, product }) => {
  const [height, setHeight] = useState('80vh');
  const { userAgent } = navigator;

  useEffect(() => {
    // grab the version number from the string
    const regex = /Version\/(.*?) Safari\//;
    const matches = userAgent.match(regex);

    // for Safari version < 15
    // change the height of the viewer
    if (
      navigator.userAgent.indexOf('Safari') !== -1 &&
      matches?.length &&
      Number(matches[1]) < 15
    ) {
      setHeight('70vh');
    }
  }, [userAgent]);

  let query = useQuery();
  const disableCart = query.get('disableCart');
  const wireframe = query.get('wireframe');
  const dimensions = query.get('dimensions');

  let viewerConfig = `{"ar-modes": "scene-viewer webxr quick-look fallback", "ar-scale": "auto", "max-field-of-view": "90deg", "min-field-of-view": "30deg"}`;
  let viewerProps = {};
  if (wireframe) viewerProps['show-wireframe'] = true;
  if (dimensions) viewerProps['show-dimensions'] = true;

  return (
    <>
      <meshkraft-viewer
        sku={sku}
        show-dimensions
        config={viewerConfig}
        height={height}
        show-banner={!disableCart}
        {...viewerProps}
      />
    </>
  );
};

export default Product;
